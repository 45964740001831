import React, {useState, ReactNode} from 'react'
import StepperItem from './StepperItem'
import RenderWhenGrid from '../RenderWhenGrid/RenderWhenGrid'
import {BootstrapUI} from '../../../domain/enums'
import Button from '../Button/Button'

interface Step {
  title: string
  description: string
  content: ReactNode
  selectable: boolean
}

interface StepperProps {
  steps: Step[]
  currentStep: number
  setCurrentStep: any
  currentDefinitiveStep: number
  lastStepCompleted: boolean
  ctaButtons?: any
}

const Stepper: React.FC<StepperProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  currentDefinitiveStep,
  lastStepCompleted,
  ctaButtons = [{step: null, action: null}],
}) => {
  const stepperNavStyle =
    steps.length > 1 ? 'stepper-nav flex-center flex-wrap mb-10' : 'stepper-nav  mb-10'
  return (
    <div className='stepper stepper-pills' id='kt_stepper_example_basic'>
      <div className={`${stepperNavStyle}`}>
        {steps.map((step, index) => (
          <StepperItem
            lastStepCompleted={lastStepCompleted}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            index={index}
            title={step.title}
            description={step.description}
            content={step.content}
            selectable={step.selectable}
            currentDefinitiveStep={currentDefinitiveStep}
          />
        ))}
      </div>
      <div className='mb-5'>
        <div className='flex-column current' data-kt-stepper-element='content'>
          {steps.map((step, index) => {
            const cta = ctaButtons.find((x: any) => x.step === index + 1)
            
            if (currentStep === index + 1) {
              return (
                <>
                  <>{step.content}</>
                  <>
                    {/* label: `View Offer Details`,
            isNavigation: true,
            navigateTo: 1,
            onClick: null, */}
                    <RenderWhenGrid visible={cta?.action}>
                      {cta?.action?.map((item: any) => {
                        if (item.isNavigation) {
                          return (
                            <Button
                              type={'button'}
                              sx={`font-size-h1 custom-btn`}
                              value={item.label}
                              theme={BootstrapUI.Custom}
                              onClick={() => setCurrentStep(item.navigateTo)}
                              style={{marginTop: `40px`}}
                            />
                          )
                        } else {
                          return (
                            <button onClick={cta.onClick} className='btn btn-primary'>
                              {item.label}
                            </button>
                          )
                        }
                      })}
                    </RenderWhenGrid>
                  </>
                </>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default Stepper
