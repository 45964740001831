import { CSSProperties } from 'react'
import {BootstrapUI} from '../../../domain/enums'

interface Props {
  onClick?: any
  theme: BootstrapUI
  disabled?: boolean | undefined
  value?: string
  loading?: boolean
  visible?: boolean
  sx?:string
  style?:CSSProperties|undefined
  type: 'button' | 'submit' | 'reset' | undefined
}

const Button = ({onClick, value, theme, disabled, type, loading,sx,style,visible=true,...props}: Props) => {
  let className = ''
  switch (theme) {
    case BootstrapUI.Primary:
      className = 'btn btn-primary btn-lg btn-block'
      break
    case BootstrapUI.Secondary:
      className = 'btn btn-secondary btn-lg btn-block'
      break
    case BootstrapUI.Success:
      className = 'btn btn-success btn-lg btn-block'
      break
    case BootstrapUI.Danger:
      className = 'btn btn-danger btn-lg btn-block'
      break
    case BootstrapUI.Warning:
      className = 'btn btn-warning btn-lg btn-block'
      break
    case BootstrapUI.Info:
      className = 'btn btn-info btn-lg btn-info'
      break
    case BootstrapUI.Light:
      className = 'btn btn-light btn-lg btn-light'
      break
    case BootstrapUI.Dark:
      className = 'btn  btn-lg btn-dark'
      break   
    case BootstrapUI.Custom:
      className = 'btn btn-lg'
      break
    case BootstrapUI.XL:
        className = 'btn btn-lg xl-btn'
        break
    default:
      className = 'btn btn-primary btn-lg btn-block'
      break
  }

  if(!visible){
    return <></>
  }
  return (
    <>
      <button disabled={disabled} type={type}  style={style} onClick={onClick} 
      // className={`btn btn-primary font-weight-bold font-size-h1 px-12 py-5`}
      className={`${className} ${sx}`}
      {...props}
      >
        {!loading && <span className=''> {value}</span>}
        {/* {!loading && <span className='indicator-label'> {value}</span>} */}
        {loading && (
          <span className='indicator-progress' style={{display: 'block'}}>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </>
  )
}

export default Button
