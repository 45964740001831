import {FC, useState} from 'react'
import PageCard from '../../components/Card/PageCard'
import {Button, H4, H5, P, RenderWhenGrid, Title} from '../../components'
import {BootstrapUI} from '../../../domain/enums'
import PageCardBody from '../../components/Card/PageCardBody'
import {useForm} from '../../../infrastructure/deps/react-form'
import ToggleButtonGroup from '../../components/ToggleButtonGroup/ToggleButtonGroup'
import {CategoryEnum, getCategoryTypeEnumOptions} from '../../../domain/enums/CategoryEnum'
import ArtistOnboardingForm from './forms/ArtistOnboardingForm'
import AgentOnboardingForm from './forms/AgentOnboardingForm'
import VenueOnboardingForm from './forms/VenueOnboardingForm'
import {createOnboardingRequest} from '../../utils/OnboardingMapper'
import {useUserOnboarding} from '../../../domain/usecase/user/useUserOnboarding'
import {UserRepositoryImpl} from '../../../infrastructure/remote/repositories/user/UserRepositoryImpl'
import {UserApi} from '../../../infrastructure/remote/api/UserApi'
import {isEmpty, isEmptyObject, isNullOrEmpty, isObjectEmpty} from '../../../domain/utils'
import {ValidateOnboardingSchema} from '../../../domain/models/validations/ArtistSchema'
import {useAuth} from '../../../infrastructure/local/auth'
import PopUp from '../../components/PopUp/PopUp'
import {useNavigate} from 'react-router-dom'
import {useLocationGetFilter} from '../../../domain/usecase/location/useLocationGetFilter'
import {LocationRepositoryImpl} from '../../../infrastructure/remote/repositories/location/LocationRepositoryImpl'
import {LocationApi} from '../../../infrastructure/remote/api/LocationApi'
import {LocationFilter} from '../../../domain/entities/filters/LocationFilter'
import {HttpStatusCode} from '../../../infrastructure/enums'
import {ErrorNotification} from '../../utils/Notification'
import {UserApiRoutes} from '../../../infrastructure/constants/api/v1'
import {Http} from '../../../infrastructure/http'
import {OnboardingRequest} from '../../../domain/models/requests/common/OnboardingRequest'
import {ValidateOnboardingRequestSchema} from '../../../domain/models/validations/OnboardingSchema'
import ContactCard from '../shared/partials/ContactCard'
import {PopupAlertError} from '../../utils/PopupNotification'

const Onboarding: FC = () => {
  const locationFilter: LocationFilter = {
    type: 'province',
    provinceId: [],
    cityId: '',
    countryId: 1,
  }
  const locationFIlterProvider = useLocationGetFilter(new LocationRepositoryImpl(new LocationApi()))
  const getAllProvinces = locationFIlterProvider(locationFilter)

  const [isOnboardingComplete, setIsOnboardingComplete] = useState(false)
  const form = useForm<any>({
    defaultValues: {
      accountType: '',
      subtype: '',
    },
  })
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    getValues,
    setError,
    clearErrors,
    formState: {errors},
  } = form
  const navigate = useNavigate()
  const performCreate = useUserOnboarding(new UserRepositoryImpl(new UserApi()))
  const selectedaccountType = watch('accountType')
  const selectedartistType = watch('artistType')
  const selectJobPosition = watch('subtype')
  const providedName = watch('name')
  // const selectedSubtype = watch('subType')
  const [step, setStep] = useState(1)
  const [searchKeyword, setSearchKeyword] = useState('')
  const [verificationError, setVerificationError] = useState('')
  const {currentUser, logout} = useAuth()

  const onUseUserLogout = async () => {
    const url = UserApiRoutes.Logout
    const res = await Http.get(url)
    console.log('logout response', res)
    if (res.data) {
      if (res.status === HttpStatusCode.OK) {
        logout()
        return navigate('/', {replace: true})
      } else {
        ErrorNotification('onsubmit-log-error-failed', 'Could not log you out. Try again later')
      }
    }
  }

  function displayErrors(schema: any) {
    clearErrors() // Clear previous errors
    if (!isEmpty(schema.errors)) {
      schema.errors.details.forEach((error: any) => {
        setError(error.context.key, error)
      })
    }
  }

  const onSubmit = async (formData: any, step: number) => {
    clearErrors()
    formData.initiated_by = 'self'
    formData.uuid = currentUser?.user?.uuid //TODO:: get this from the currently signed in user. This is the user accounts master UUID
    const request: OnboardingRequest | undefined = createOnboardingRequest(formData)

    if (step === 1) {
      const validateOnboardingRequest = await ValidateOnboardingRequestSchema(request)
      if (!isObjectEmpty(validateOnboardingRequest)) {
        PopupAlertError(
          'Account creation failed',
          'Please correct all highlighted errors to complete account registration.'
        )
        displayErrors(validateOnboardingRequest)
      } else {
        setStep(2)
      }
    } else if (step === 2 && request) {
      clearErrors() // Clear previous errors
      let failedVerificationUrl = false
      if (
        isNullOrEmpty(request.artist?.artist?.spotify) &&
        isNullOrEmpty(request.artist?.artist?.facebook) &&
        isNullOrEmpty(request.artist?.artist?.instagram) &&
        isNullOrEmpty(request.artist?.artist?.youtube) &&
        isNullOrEmpty(request.artist?.artist?.soundcloud)
      )
        failedVerificationUrl = true

      if (failedVerificationUrl) {
        PopupAlertError('Validation failed', 'Provide one or more verification links')
      } else {
        performCreate.mutate(request, {
          onSuccess: (response) => {
            const {status} = response
            if (status === HttpStatusCode.OK || status === HttpStatusCode.Created) {
              setIsOnboardingComplete(true)
            } else {
              PopupAlertError(
                'Account creation not completed',
                'Account creation did not complete successfully'
              )
            }
          },
          onError: (error: any) => {
            const {data} = error.response
            if (data.code === 400) {
              PopupAlertError('Account creation not completed', data.message)
            } else {
              PopupAlertError(
                'Registration not completed',
                'Account creation did not complete successfully'
              )
            }
          },
        })
      }
    }
  }

  console.log("", errors)
  return (
    <>
      <PageCard>
        <PageCardBody>
          <Title type='xxs'>Thank you for being a part of the community.</Title>
          <H5>Let’s get you signed up</H5>
          <RenderWhenGrid visible={step === 1}>
            <div className='row'>
              <div className='col-md-12 mt-5'>
                <ToggleButtonGroup
                  name={'accountType'}
                  label={'Type'}
                  type='exclusive'
                  options={getCategoryTypeEnumOptions()}
                  control={control}
                />
              </div>
            </div>
            <RenderWhenGrid visible={selectedaccountType === CategoryEnum.Artist}>
              <ArtistOnboardingForm
                control={control}
                watch={watch}
                errors={errors}
                provinces={getAllProvinces.data?.data}
              />
            </RenderWhenGrid>
            <RenderWhenGrid visible={selectedaccountType === CategoryEnum.Agent}>
              <AgentOnboardingForm
                control={control}
                watch={watch}
                errors={errors}
                provinces={getAllProvinces.data?.data}
              />
            </RenderWhenGrid>
            <RenderWhenGrid visible={selectedaccountType === CategoryEnum.Venue}>
              <VenueOnboardingForm
                control={control}
                watch={watch}
                errors={errors}
                provinces={getAllProvinces.data?.data}
              />
            </RenderWhenGrid>
            <div className='row'>
              <div className='col-md-12'>
                {!isEmptyObject(errors) ? (
                  <span className='error text-danger'>
                    Complete the required fields before you proceed
                  </span>
                ) : (
                  ''
                )}
              </div>
              <div className='col-md-12'>
                <Button
                  theme={BootstrapUI.Custom}
                  type={'button'}
                  sx={`font-size-h1 custom-btn`}
                  value='Next'
                  disabled={isNullOrEmpty(selectedaccountType) || isNullOrEmpty(selectedartistType)}
                  visible={
                    !isNullOrEmpty(selectedaccountType) && !isNullOrEmpty(selectedartistType)
                  }
                  loading={performCreate.isLoading}
                  onClick={handleSubmit((formData: any) => {
                    clearErrors()
                    onSubmit(formData, 1)
                  })}
                ></Button>
              </div>
            </div>
          </RenderWhenGrid>
          <RenderWhenGrid visible={step === 2}>
            {/* Step 2 */}

            <div className='col-md-12 mt-5'>
              <H4>Hi {providedName},</H4>
              <H4>To verify your identity, we need a bit more information</H4>
              <ContactCard
                id={''}
                label=''
                control={control}
                errors={errors}
                website={false}
                phone={false}
                emailAddress={false}
                spotify={true}
                facebook={true}
                facebookId={'facebook'}
                instagram={true}
                instagramId={'instagram'}
                youtube={true}
                youtubeId={'youtube'}
                tiktok={false}
                tiktokId={'tiktok'}
                shareProfile={false}
                showSocials={false}
                representingArtist={false}
                representingLabelOrAgency={false}
                soundCloud={true}
                name={false}
              />
            </div>
            <div className='col-md-12'>
              {!isNullOrEmpty(verificationError) ? (
                <span className='error text-danger'>Provide atleast one form of verification</span>
              ) : (
                ''
              )}
            </div>

            <div className='col-md-12'>
              <div className='d-flex justify-content-between'>
                <Button
                  theme={BootstrapUI.Custom}
                  type={'submit'}
                  sx={`font-size-h1 custom-btn`}
                  value='Previous'
                  disabled={isNullOrEmpty(selectedaccountType)}
                  visible={
                    !isNullOrEmpty(selectedaccountType) && !isNullOrEmpty(selectedartistType)
                  }
                  loading={performCreate.isLoading}
                  onClick={() => setStep(1)}
                ></Button>
                <Button
                  theme={BootstrapUI.Custom}
                  type={'submit'}
                  sx={`font-size-h1 custom-btn`}
                  value='Submit'
                  disabled={isNullOrEmpty(selectedaccountType)}
                  visible={
                    !isNullOrEmpty(selectedaccountType) && !isNullOrEmpty(selectedartistType)
                  }
                  loading={performCreate.isLoading}
                  onClick={handleSubmit((formData: any) => {
                    clearErrors()
                    onSubmit(formData, 2)
                  })}
                ></Button>
              </div>
            </div>
          </RenderWhenGrid>
          <PopUp
            isOpen={isOnboardingComplete}
            setIsOpen={setIsOnboardingComplete}
            title={'Account Creation Completed'}
            onClose={() => onUseUserLogout()}
            onProceed={() => onUseUserLogout()}
          >
            <H5>
              Congratulations! You have successfully created a new profile and joined our community.{' '}
            </H5>
            <P>
              Union Station is still in Beta, but you are free to update your profile to reflect any
              changes. We will keep you informed of any new features and updates.
            </P>
          </PopUp>
        </PageCardBody>
      </PageCard>
    </>
  )
}

export default Onboarding
function setStep(arg0: number) {
  throw new Error('Function not implemented.')
}
