import Joi from 'joi'

const OnboardingRequestSchema = Joi.object({
  type: Joi.string().valid('Artist', 'Agent', 'Venue').required(),
  initiated_by: Joi.string().valid('Self', 'Admin', 'self-join','self').required(),
  artist: Joi.object().keys({
    artist: Joi.object({
      id: Joi.number().optional(),
      user_id: Joi.number().optional(),
      type: Joi.string()
        .valid(
          'PerformerBand',
          'SessionArtistInstrumentals',
          'RecordProducerStudioEngineer',
          'TeacherClinician'
        )
        .required(),
      name: Joi.string().required().label('Name'),
      legacy_uuid: Joi.string().optional().allow(""),
      location_id: Joi.string().required().label('Location/Province'),
      pref_city_id: Joi.string().required().label('Location/City'),
      performance_preferred_venues: Joi.string().optional().allow(""), // TODO:: enable later
      website: Joi.string().uri().optional().allow(""),
      contact: Joi.string().required().label('Email Address'),
      phone: Joi.string().required().label("Phone Number"),
      phone_main: Joi.string().optional().allow(""),
      phone_other: Joi.string().optional().allow(''),
      other_name: Joi.string().optional().allow(''),
      youtube: Joi.string().uri().optional().allow(''),
      wikipedia: Joi.string().uri().optional().allow(''),
      spotify: Joi.string().uri().optional().allow(''),
      apple_music: Joi.string().uri().optional().allow(""),
      google_play: Joi.string().uri().optional().allow(""),
      itunes: Joi.string().uri().optional().allow(''),
      size: Joi.string().optional().allow(''),
      has_label: Joi.boolean().allow(null).optional(),
      label_name: Joi.string().optional().allow(''),
      minimum_price: Joi.number().required().label('Per Service Amount'),
      has_hourly_rate: Joi.boolean().allow(null).optional(),
      genres: Joi.string().allow('').optional(),
      active: Joi.boolean().optional().allow(null),
      contact_firstname: Joi.string().optional().allow(''),
      contact_lastname: Joi.string().optional().allow(''),
      affiliations: Joi.string().optional().allow(''),
      // performance_preference: Joi.string().required().label('Performance Locations'),
      ethnic_identity: Joi.string().optional().allow(''),
      repesenting_artist: Joi.boolean().allow(null).optional(),
      representing_label_agency: Joi.boolean().allow(null).optional(),
      receive_charity_offers: Joi.boolean().allow(null).optional(),
      facebook: Joi.string().uri().optional().allow(''),
      instagram: Joi.string().uri().optional().allow(''),
      tiktok: Joi.string().uri().optional().allow(''),
      contact_name: Joi.string().required().label('Contact Name'),
    }).optional(),
    performerBand: Joi.object({
      subtype: Joi.string().required().label('Position'),
      name: Joi.string().required().label('Name'),
      plays_originals_covers_both: Joi.string().optional().allow(''),
    })
      .optional()
      .allow({}),
    //   .optional()
    //   .allow(null), // Replace with your `PerformerBand` validation schema
    // recordProducerStudioEngineer: Joi.object().optional(), // Replace with your `RecordProducerStudioEngineer` validation schema
    // sessionArtistInstrumentals: Joi.object().optional(), // Replace with your `SessionArtistInstrumentals` validation schema
    // teacherClinician: Joi.object().optional(), // Replace with your `TeacherClinician` validation schema
    // uuid: Joi.string().uuid().required(),
  }),
})

export async function ValidateOnboardingRequestSchema(fields: any) {
  
  let errors: any = {}
  try {
    await OnboardingRequestSchema.validateAsync(
      {...fields},
      {abortEarly: false, allowUnknown: true}
    )
  } catch (err) {
    errors['errors'] = err
  }
  return errors
}

export {OnboardingRequestSchema}
