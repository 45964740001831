import {ReactNode} from 'react'
import {AiOutlineArrowRight, AiOutlineCheckCircle} from 'react-icons/ai'

interface Props {
  currentStep: number
  index: number
  content: ReactNode
  title: ReactNode
  description: ReactNode
  setCurrentStep: any
  selectable: boolean
  currentDefinitiveStep: number
  lastStepCompleted: boolean
}

const StepperItem = ({
  currentStep,
  index,
  content,
  title,
  description,
  setCurrentStep,
  selectable,
  currentDefinitiveStep,
  lastStepCompleted,
}: Props) => {

  const StepIcon = () => {
    
    if ((index+1) === currentDefinitiveStep) {
      if (lastStepCompleted) {
        return <AiOutlineCheckCircle size={32} className='text-success' />
      } else {
        return <AiOutlineArrowRight size={32} />
      }
    } else {
      if ((index+1) < currentDefinitiveStep) {
        return <AiOutlineCheckCircle size={32} className='text-success' />
      } else {
        return <AiOutlineArrowRight size={32} />
      }
    }
  }

  const StepperContent = () => {

    return (
      <div
        key={index}
        className={`stepper-item mx-2 my-4 ${currentStep === index + 1 ? 'current' : ''}`}
        data-kt-stepper-element='nav'
      >
        <div className='stepper-line w-40px'></div>
        <div className=' w-40px h-40px'>
          <StepIcon/>
        </div>
        <div className='stepper-label'>
          <h3 className='stepper-title'>{title}</h3>
          <div className='stepper-desc'>{description}</div>
        </div>
      </div>
    )
  }

  if (selectable || currentDefinitiveStep === index + 1)
    return (
      <a href='#' onClick={() => setCurrentStep(index + 1)}>
        <StepperContent />
      </a>
    )
  return <StepperContent />
}

export default StepperItem
